<template>
  <div class="body">
		<slot></slot>
	</div>
</template>

<script>
export default {
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';
.body{
	width: var(--body-width);
	padding: var(--body-padding);
	border-left: var(--body-border-left);
	border-right: var(--body-border-right);
}
.page--layout-sidebar{
	.body{
		padding: var(--body-sidebar-mobile-padding);
		@include breakpoint(md){
			padding: var(--body-padding);
			grid-column-start: var(--body-sidebar-column-start);
			grid-column-end: var(--body-sidebar-column-end);
			grid-row-start: var(--body-sidebar-row-start);
			grid-row-end: var(--body-sidebar-row-end);
		}
	}
}
</style>

<!-- Used in : AR, DS -->